<template>
    <div class="assessment-result-container">
        <div class="assessment-result" v-if="!showModal">
            <p class="title">{{ $t('assessment_result') }}</p>

            <div class="result-container">
                <div class="card-container">
                    <div
                        class="item"
                        v-for="(item, index) in top3"
                        :key="index"
                    >
                        <p class="place">{{ ordinalSuffix(item.order) }}</p>

                        <div @click="openCard(item.assessment_type_id)" class="card">
                            <img :src="require(`@/assets/img/results/${item.assessment_type_id}-${language}.png`)" alt="card">

                            <div class="indicators">
                                <div class="points">
                                    <span>{{ item.type_count }}</span>
                                </div>
                                <div class="percent">
                                    <span>{{ item.percent }}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="card-container">
                    <div
                        class="item"
                        v-for="(item, index) in top6"
                        :key="index"
                    >
                        <p class="place">{{ ordinalSuffix(item.order) }}</p>

                        <div @click="openCard(item.assessment_type_id)" class="card">
                            <img class="lower" :src="require(`@/assets/img/results/${item.assessment_type_id}-${language}.png`)" alt="card">

                            <div class="indicators">
                                <div class="points lower">
                                    <span>{{ item.type_count }}</span>
                                </div>
                                <div class="percent lower">
                                    <span>{{ item.percent }}%</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <button @click="goHome">{{ $t('return_home') }}</button>
            </div>
        </div>

        <transition name="fade">
            <div class="modal-container" v-if="showModal">
                <div class="modal">
                    <img @click="showModal = false" class="close" :src="require('@/assets/img/close.svg')" alt="close">
                    <CardView :index="index"></CardView>
                </div>
            </div>
        </transition>

        <div class="left">
            <img :src="require('@/assets/img/confetti_left.png')" alt="card">
        </div>
        <div class="right">
            <img :src="require('@/assets/img/confetti_right.png')" alt="card">
        </div>
    </div>
</template>

<script>
import { mapFields } from 'vuex-map-fields'

export default {
    props: ['id'],

    data () {
        return {
            showModal: false,
            index: 0       
        }
    },

    components: { 
        CardView: () => import('@/components/game/CardView'),
    },

    computed: {
        ...mapFields(['result', 'language']),

        top3 () {
            let arr = []
            this.result.map((x,i)=> { if (i<3) arr.push(x)})
            return arr
        },

        top6 () {
            let arr = []
            this.result.map((x,i)=> { if (i>2) arr.push(x)})
            return arr
        }
    },

    async created () {
        await this.$store.dispatch('getResult', { assessment_id: this.id })

        if (this.result.length < 1) {
            this.$router.push({ name: 'PageNotFound' })
        }
    },

    methods: {
        openCard (i) {
            this.index = i
            this.showModal = true
        },

        ordinalSuffix (i) {
            var j = i % 10,
                k = i % 100;
            if (j == 1 && k != 11) {
                return i + "st";
            }
            if (j == 2 && k != 12) {
                return i + "nd";
            }
            if (j == 3 && k != 13) {
                return i + "rd";
            }
            return i + "th";
        },

        goHome () {
            this.$store.commit('CLEAR_GAME_VARIABLES')
            this.$router.push('/dashboard')
        }
    },
}
</script>

<style lang="scss" scoped>
.assessment-result-container {
    position: relative;
    background-image: url('~@/assets/img/assessment_bg.png');
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;

    .assessment-result {    
        max-width: 1200px;
        margin: auto;
        padding: 25px;
    
        .title {
            width: 100%;
            font-family: 'Crimson Pro', sans-serif;
            font-weight: 700;
            font-size: 26px;
            padding-bottom: 24px;
            margin-bottom: 40px;
            border-bottom: 1px solid #B8B8B8;
            text-align: center;
        }
        
    
        .result-container {
            display: flex;
            flex-direction: column;
    
            .card-container {
                display: flex;
                justify-content: center;
                align-items: center;
                flex-wrap: wrap;
                margin-bottom: 35px;
    
                .item {
                    text-align: center;
                    margin-right: 10px;
    
                    .place {
                        font-weight: 600;
                        font-size: 20px;
                        margin-bottom: 10px;
                    }
                    
                    .card {
                        cursor: pointer;
                        position: relative;
    
                        img {
                            width: 210px;
                            height: 280px;
                            

                            @include desktop {
                                width: 210px;
                                height: 280px;
                            }
    
                            &.lower {
                                width: 140px;
                                height: 205px;

                                @include desktop {
                                    width: 140px;
                                    height: 205px;
                                }
                            }
                        }

                        .indicators {
                            display: flex;
                            justify-content: center;
                            align-items: center;

                            position: absolute;
                            margin: auto;
                            bottom: 8%;
                            left: 0;
                            right: 0;

                            .points {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                background-color: #45B171;
                                margin-right: 10px;
        
                                &.lower {
                                    width: 40px;
                                    height: 40px;
                                }
        
                                span {
                                    color: #ffffff;
                                    font-size: 18px;
        
                                    &.lower {
                                        font-size: 14px;
                                    }
                                }
                            }
        
                            .percent {
                                display: flex;
                                justify-content: center;
                                align-items: center;
                                width: 60px;
                                height: 60px;
                                border-radius: 50%;
                                background-color: #ffffff;
                                border: 2px solid #00AEEF;
        
                                &.lower {
                                    width: 40px;
                                    height: 40px;
                                }
        
                                span {
                                    color: #00AEEF;
                                    font-size: 18px;
        
                                    &.lower {
                                        font-size: 14px;
                                    }
                                }
                            }
                        }

                    }
                }
            }
    
            button {
                margin: auto;
                width: 176px;
                border: 1px solid #00AEEF;
                background: #ffffff;
                color: #00AEEF;
                margin-bottom: 20px;
            }
        }
    }

    .fade-enter {
        opacity: 0;
    }
    .fade-enter-active {
        transition: opacity .5s;
    }
    .fade-leave {
        opacity: 1;
    }
    .fade-leave-active {
        transition: opacity .5s;
        opacity: 0;
    }

    .left {
        position: absolute;
        left: 0;
        bottom: 0;

        img {
            width: 110px;
            height: 110px;
    
            @include desktop {
                width: unset;
                height: unset;
            }
        }

    }

    .right {
        position: absolute;
        right: 0;
        bottom: 0;

        img {
            width: 110px;
            height: 110px;
    
            @include desktop {
                width: unset;
                height: unset;
            }
        }

    }

    .modal-container {
        position: fixed;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        // background: #000000a1;
        z-index: 999999;
        overflow-y: auto;


        .modal {
            width: 100%;
            background: #FFF8F1;

            position: absolute;
            padding: 20px;
            overflow: auto;

            .close {
                cursor: pointer;
                position: absolute;
                top: 20px;
                right: 20px;
            }
        }
    }
}
</style>